import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { H1 } from '../../../components';
import heroImg from './hero-img.png';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.titlesHolder}>
          <H1 className={css.heroMainTitle}>
            <span className={css.darkColor}>
              <FormattedMessage id="SectionFAQHero.title1" />
            </span>
            <FormattedMessage id="SectionFAQHero.title2" />

          </H1>
        </div>
        <div className={css.imageHolder}>
          <img className={css.image} src={heroImg} alt="" />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
