import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ExternalLink, NamedLink } from '../../components';


export const array = [
  {
    id: 1,
    head: <FormattedMessage id="FAQPage.accTitle1" />,
    innerArr: [
      {
        id: 1,
        desc: <FormattedMessage id="FAQPage.accDscr1" />,
      },
    ],
  },
  {
    id: 2,
    head: <FormattedMessage id="FAQPage.accTitle2" />,
    innerArr: [
      { id: 1, desc: <FormattedMessage id="FAQPage.accDscr2" /> },
    ],
  },
  { id: 3,
    head: <FormattedMessage id="FAQPage.accTitle3" />,
    innerArr: [
      { id: 1, desc: <FormattedMessage id="FAQPage.accDscr3" /> }
    ]},
  {
    id: 4,
    head: <FormattedMessage id="FAQPage.accTitle4" />,
    innerArr: [
      { id: 1, desc: <FormattedMessage id="FAQPage.accDscr4" values={{
          link: <NamedLink name="SignupPage" >
            here
          </NamedLink>
        }} /> },
    ],
  },
  {
    id: 5,
    head: <FormattedMessage id="FAQPage.accTitle5" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr5" /> }],
  },
  {
    id: 6,
    head: <FormattedMessage id="FAQPage.accTitle6" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr6" /> }],
  },
  {
    id: 7,
    head: <FormattedMessage id="FAQPage.accTitle7" />,
    innerArr: [{ id: 1, desc:   <FormattedMessage
        id="FAQPage.accDscr7"
        values={{
          link: <ExternalLink href='mailto:support@therapaide.com' >
            support@therapaide.com
          </ExternalLink>
        }}
      />}],
  },
  {
    id: 8,
    head: <FormattedMessage id="FAQPage.accTitle8" />,
    innerArr: [{
      id: 1,
      desc: <FormattedMessage id="FAQPage.accDscr8" />
    }],
  },
  {
    id: 9,
    head: <FormattedMessage id="FAQPage.accTitle9" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr9" /> }],
  },
  {
    id: 10,
    head: <FormattedMessage id="FAQPage.accTitle10" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr10" /> }],
  },
  {
    id: 11,
    head: <FormattedMessage id="FAQPage.accTitle11" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr11" /> }],
  },
  {
    id: 12,
    head: <FormattedMessage id="FAQPage.accTitle12" />,
    innerArr: [{ id: 1, desc: <FormattedMessage id="FAQPage.accDscr12" /> }],
  },

];
