import React from 'react';

import css from './SectionSimpleDescription.module.css';

const SectionSimpleDescription = props => {
  return (
    <div className={css.root}>
      <p className={css.description}>{props.description}</p>
    </div>
  );
};

export default SectionSimpleDescription;
