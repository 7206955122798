import React from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Button, FieldTextInput, Form } from '../../../components';


import css from './ContactForm.module.css';
import { composeValidators, required } from '../../../util/validators';

const identity = v => v;



const ContactFormComponent = props => {

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          handleSubmit,
          updated,
          pristine,
          ready,
          invalid,
          disabled,
        } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        const nameRequiredMessage = intl.formatMessage({ id: 'ContactForm.nameIsRequired' })
        const emailRequiredMessage = intl.formatMessage({ id: 'ContactForm.emailIsRequired' })
        const messageRequiredMessage = intl.formatMessage({ id: 'ContactForm.messageIsRequired' })
        const btnText = intl.formatMessage({ id: 'ContactForm.btnText' });

        const submitReady = (updated && pristine) || ready;
        // const submitDisabled = invalid || disabled;
        const submitDisabled = false;



        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldTextInput
              id="name"
              name="name"
              inputRootClass={css.input}
              type="text"
              placeholder={intl.formatMessage({ id: 'ContactForm.namePlaceholder' })}
              validate={composeValidators(required(nameRequiredMessage))}
            />

            <FieldTextInput
              id="email"
              name="email"
              inputRootClass={css.input}
              type="text"
              placeholder={intl.formatMessage({ id: 'ContactForm.emailPlaceholder' })}
              validate={composeValidators(required(emailRequiredMessage))}
            />

            <FieldTextInput
              id="address"
              name="address"
              inputRootClass={css.input}
              type="text"
              placeholder={intl.formatMessage({ id: 'ContactForm.addressPlaceholder' })}
            />

            <FieldTextInput
              id="address"
              name="address"
              inputRootClass={css.textarea}
              type="textarea"
              placeholder={intl.formatMessage({ id: 'ContactForm.messagePlaceholder' })}
              validate={composeValidators(required(messageRequiredMessage))}
            />

            <Button
              className={css.button}
              type="submit"
              disabled={submitDisabled}
              ready={submitReady}
            >
              {btnText}
            </Button>
          </Form>
        );
      }}
    />
  );
};

ContactFormComponent.defaultProps = { rootClassName: null, className: null };

ContactFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  isKeywordSearch: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactForm = injectIntl(ContactFormComponent);

export default ContactForm;
