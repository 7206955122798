import React from 'react';
import css from './FAQPage.module.css';

const Accordion = ({ ar, selectedText, selectRole }) => {
  return (
    <div className={css.inner}>
      {ar.innerArr.map((iArray, i) => (
        <p
          key={i}
          onClick={() => selectRole(i, iArray)}
          className={selectedText === iArray.desc ? 'selected' : ''}
        >
          {iArray.desc}
        </p>
      ))}
    </div>
  );
};

export default Accordion;
