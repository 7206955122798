import React from "react";
import ToggleIcon from "./ToggleIcon";
import { H3 } from '../../components';

import css from './FAQPage.module.css';

const AccordionHeader = ({ ar, oIndex, index, selectedText }) => {

  return (
    <div className={css.tile}>
      <div className={css.left}>
        <H3 className={css.title}>{ar.head}</H3>
      </div>
      <div className={css.iconHolder}>
        <ToggleIcon state={oIndex === index ? true : false} />
      </div>
    </div>
  );
}
export default AccordionHeader;
