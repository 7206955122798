import React from 'react';

import css from './PersonBookIcon.module.css';

const PersonBookIcon = () => {
  return (
    <svg
      className={css.root}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_374_70925)">
        <path
          d="M43.75 22.9167H33.3333C29.8875 22.9167 27.0833 25.7208 27.0833 29.1667V43.75C27.0833 47.1958 29.8875 50 33.3333 50H43.75C47.1958 50 50 47.1958 50 43.75V29.1667C50 25.7208 47.1958 22.9167 43.75 22.9167ZM41.6667 41.6667H35.4167C34.2646 41.6667 33.3333 40.7333 33.3333 39.5833C33.3333 38.4333 34.2646 37.5 35.4167 37.5H41.6667C42.8188 37.5 43.75 38.4333 43.75 39.5833C43.75 40.7333 42.8188 41.6667 41.6667 41.6667ZM41.6667 33.3271H35.4167C34.2646 33.3271 33.3333 32.3937 33.3333 31.2437C33.3333 30.0937 34.2646 29.1604 35.4167 29.1604H41.6667C42.8188 29.1604 43.75 30.0937 43.75 31.2437C43.75 32.3937 42.8188 33.3271 41.6667 33.3271ZM6.25 12.5C6.25 5.60625 11.8563 0 18.75 0C25.6438 0 31.25 5.60625 31.25 12.5C31.25 19.3937 25.6438 25 18.75 25C11.8563 25 6.25 19.3937 6.25 12.5ZM25.0542 50H2.08334C0.92292 50 -0.00207982 49.0417 3.51222e-06 47.8812C0.0187535 37.5583 8.42292 29.1667 18.75 29.1667C20.1833 29.1667 21.575 29.3438 22.9167 29.65V43.75C22.9167 46.1042 23.7313 48.2542 25.0542 50Z"
          fill="#FFAEAB"
        />
      </g>
      <defs>
        <clipPath id="clip0_374_70925">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonBookIcon;
