import React from 'react';

const ToggleIcon = ({ className, state }) => {

  const viewBox = state ? '0 0 18 4' : '0 0 18 18';
  const height = state ? '4' : '18';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width="18"
      height={height}
    >
      {!state ? (
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9 0C9.62134 0 10.125 0.503685 10.125 1.125V7.875H16.875C17.4963 7.875 18 8.37866 18 9C18 9.62134 17.4963 10.125 16.875 10.125H10.125V16.875C10.125 17.4963 9.62134 18 9 18C8.37866 18 7.875 17.4963 7.875 16.875V10.125H1.125C0.503685 10.125 0 9.62134 0 9C0 8.37866 0.503685 7.875 1.125 7.875H7.875V1.125C7.875 0.503685 8.37866 0 9 0Z"
              fill="white" />

      ) : (
        <path
          d="M16.875 0.875H10.125H1.125C0.503685 0.875 0 1.37866 0 2C0 2.62134 0.503685 3.125 1.125 3.125H10.125H16.875C17.4963 3.125 18 2.62134 18 2C18 1.37866 17.4963 0.875 16.875 0.875Z"
          fill="white" />
      )}
    </svg>
  );
};
export default ToggleIcon;
