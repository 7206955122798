import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Page, LayoutSingleColumn } from '../../components';

import { array } from './data';
import Accordion from './Accordion';
import AccordionHeader from './AccordionHeader';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import SectionHero from './SectionHero/SectionHero';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { intlShape } from '../../util/reactIntl';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';

import css from './FAQPage.module.css';

class FAQPageComponent extends Component {
  state = {
    arr: array,
    oIndex: null,
    iIndex: null,
    selectedText: null,
  };
  handleTile = index => {
    if (!(this.state.oIndex === index)) {
      this.setState({ oIndex: index });
    } else {
      this.setState({ oIndex: null });
    }
  };
  selectRole = (i, iArray) => {
    this.setState({
      iIndex: i,
      selectedText: iArray.desc,
    });
  };

  render() {
    const { selectedText, oIndex } = this.state;
    const { scrollingDisabled } = this.props;

    return (
      <Page title={'FAQ Page'} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <SectionHero />
          <div className={css.accordionSection}>
            <div className={css.section}>
              {this.state.arr.map((ar, index) => (
                <div className={css.heading} key={index} onClick={() => this.handleTile(index)}>
                  <AccordionHeader
                    ar={ar}
                    oIndex={oIndex}
                    index={index}
                    selectedText={selectedText}
                  />

                  {oIndex === index ? (
                    <Accordion ar={ar} selectedText={selectedText} selectRole={this.selectRole} />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

FAQPageComponent.defaultProps = {
  staticContext: {},
};

FAQPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // The context object from StaticRouter,
  // it should be manually passed if this page is rendered inside other route.
  staticContext: object,

  // from useIntl
  intl: intlShape,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route),

  // from useHistory
  history: shape({
    push: func.isRequired,
  }),
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const FAQPage = compose(connect(mapStateToProps))(FAQPageComponent);

export default FAQPage;
