import React from 'react';

import css from './IconNote.module.css';

const NoteIcon = () => {
  return (
    <svg
      className={css.root}
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_374_70874)">
        <path
          d="M13.75 0C6.15542 0 0 6.15542 0 13.75C0 21.3446 6.15542 27.5 13.75 27.5C21.3446 27.5 27.5 21.3446 27.5 13.75C27.5 6.15542 21.3446 0 13.75 0ZM22.2108 13.1129L15.9958 19.0827C14.96 20.1048 13.585 20.6204 12.2077 20.6204C10.8304 20.6204 9.44854 20.1048 8.39438 19.0758L5.80021 16.5367C4.895 15.6498 4.88125 14.1992 5.76583 13.2963C6.65042 12.391 8.10104 12.3773 9.00625 13.2596L11.6004 15.7988C11.9304 16.1242 12.469 16.1219 12.7967 15.7988L19.0369 9.80833C19.949 8.93521 21.4019 8.96042 22.2773 9.87479C23.155 10.7869 23.1252 12.2352 22.2108 13.1129ZM30.4081 35.4773C28.545 37.3404 27.4977 39.8658 27.4977 42.5013V45.8356H30.8321C33.4675 45.8356 35.9929 44.7883 37.856 42.9252L53.4554 27.3258C55.511 25.2702 55.511 21.9335 53.4554 19.8779C51.3998 17.8223 48.0631 17.8223 46.0075 19.8779L30.4081 35.4773ZM48.1227 39.1394V45.8356C48.1227 50.8979 44.0183 55.0023 38.956 55.0023H16.0417C10.9794 55.0023 6.875 50.8979 6.875 45.8356V30.7358C8.99938 31.5998 11.3185 32.0856 13.75 32.0856C23.8585 32.0856 32.0833 23.8608 32.0833 13.7523C32.0833 12.9754 32.0192 12.2123 31.9252 11.4606H38.9583C41.6923 11.4606 44.1237 12.689 45.8035 14.5933C44.7035 15.0723 43.6654 15.7392 42.7671 16.6375L27.1654 32.2392C24.4452 34.9617 22.9144 38.6512 22.9144 42.5013V45.8356C22.9144 48.3679 24.9654 50.419 27.4977 50.419H30.8321C34.6798 50.419 38.3717 48.8904 41.0942 46.1679L48.1227 39.1394Z"
          fill="#FFAEAB"
        />
      </g>
      <defs>
        <clipPath id="clip0_374_70874">
          <rect width="55" height="55" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoteIcon;
