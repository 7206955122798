import React from 'react';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionBecomeSeller.module.css';

const SectionBecomeSeller = props => {
  const { icon, title, description, linkName, linkText, className } = props;

  const classes = classNames(css.root, className);

  return (
    <section className={classes}>
      <div className={css.iconWrapper}>{icon}</div>
      <h1 className={css.heading}>{title}</h1>
      <div className={css.descriptionWrapper}>
        <p className={css.description}>{description}</p>
      </div>
      <div className={css.btnWrapper}>
        <NamedLink name={linkName} className={css.signupLink}>
          {linkText}
        </NamedLink>
      </div>
    </section>
  );
};

export default SectionBecomeSeller;
